@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fadeIn($duration: 1s, $delay: 200ms) {
  animation: $duration ease $delay fadeIn;
  animation-fill-mode: backwards;
}

@import './src/sass//abstracts/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main {
  display: flex;
  flex: 1;
  padding-top: 7px;
  background-color: rgba(232, 232, 232, 0.4);

  @media only screen and (max-width: $bp-md) {
    flex-direction: column;
  }
}

.codeEditor {
  flex: 0 1 49%;
  border: 1px solid darkgrey;
  box-sizing: border-box;
  margin: 6px;
  display: flex;
}

.separator {
  flex: 0 0 30px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $bp-md) {
    align-items: center;
  }
}

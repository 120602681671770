@import './src/sass/abstracts/all';
@import './src/sass/base/all';

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.convertScreen {
  display: flex;
  flex: 1;
  box-sizing: border-box;
}

.loader {
  background-color: rgba(lightgrey, 0.6);
  user-select: none;
  flex: 1;
  width: 100%;
  z-index: 999;
  overflow: hidden;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

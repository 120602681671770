@import './src/sass//abstracts/all';

.root {
  display: flex;
  margin-top: 5px;

  @media screen and (max-width: $bp-sm) {
    flex-direction: column;
  }
}

.buttons {
  display: flex;

  .button {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media screen and (max-width: $bp-sm) {
    align-self: center;
  }
}

.options {
  display: flex;
  flex: 1;
  padding: 10px;

  .option {
    flex: 1 1 140px;
    margin-left: 5px;
    margin-right: 5px;
  }

  @media screen and (max-width: $bp-md) {
    flex-wrap: wrap;

    .option {
      flex: 1 0 140px;
      max-width: 30%;
    }
  }
}

.styleOption {
  label {
    color: rgba(207, 164, 164, 0.9) !important;
  }
}

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.code {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
}

@import './src/sass/abstracts/all';

.header {
  flex: 1;
  font-family: 'Secular One', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
}

.link,
.link:link,
.link:visited,
.link:hover {
  text-decoration: none;
  color: white;
  font-size: 3.4rem;
  transition: 0.15s all;
  padding: 0.14rem 0;
}

.link:hover {
  transform: scale(1.17);
  z-index: 10;
}

.link:not(:last-child) {
  margin-right: 1rem;
}
